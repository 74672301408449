<template>
  <b-container>
    <edit-form
      :merchant="merchant"
      button-text="Editar"
      @click-button="editMerchant"
    />
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import MerchantService from '@/services/osiris/MerchantService';

import EditForm from './components/Form.vue';

export default {
    components: {
        BContainer,
        EditForm,
    },

    data() {
        return ({
            merchant: { address: 'BR' },
            id: this.$route.params.id,
        });
    },

    mounted() {
        this.getMerchantDataById(this.id);
    },

    methods: {
        async editMerchant() {
            const isConfirmed = await this.confirmAnAction('Você deseja realmente editar esse estabelecimento?');
            if (!isConfirmed) return;

            this.isLoading(true);
            const { status } = await MerchantService.update(this.id, {
                ...this.merchant,
                tax_id: this.merchant.tax_id.replace(/[^\d]+/g, ''),
                address: this.merchant.address.postalCode ? this.merchant.address : undefined,
            });
            this.isLoading(false);

            if (status === 200 || status === 201) {
                this.modalSuccess('Estabelecimento editado com sucesso');
                this.$router.push('/');
            } else {
                this.modalError('Não foi possível editar esse estabelecimento');
            }
        },

        async getMerchantDataById(id) {
            this.isLoading(true);
            const { data, status } = await MerchantService.show({ id });

            if (status !== 200) {
                this.$router.push('error-404');
            }

            this.merchant = {
                ...data,
                address: {
                    country: 'BR',
                },
            };
            this.isLoading(false);
        },
    },
};
</script>
